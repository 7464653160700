<template>
    <v-container class="l-primary-font f20 fw600 d-flex flex-column mb-10 mt-3 fade">
        <h3>
            Other Course Offerings
        </h3>
        <v-divider class="l-primary my-3 " :width="!$vuetify.breakpoint.mobile ? '50%' : '100%'"/>
        <SearchBar 
            :outlined="true" 
            class="ml-auto mb-5"
            :placeholder="'Search course'" 
            :disabled="loadingDates || loadingCourses" 
            :value.sync="search" 
            :landing="true"
            @search="updateQuery()" 
            @clear="() => { search = '', updateQuery() }"/>
        
        <section class="d-flex flex-wrap">
            <v-skeleton-loader
                v-if="loadingDates"
                v-for="item in [1, 2, 3]"
                :key="item"
                width=70
                height=20
                type="image"
                class="my-1 border mr-2"
            ></v-skeleton-loader>
        </section>

        <v-chip-group v-if="!loadingDates" @change="selectedDateChanged" column v-model="filter.date" active-class="l-primary white--text">
            <v-chip dense value="all" class="fade f16 fw500" :class="filter.date !== 'all' && 'secondary-2--text'">ALL</v-chip>
            <v-chip v-for="item in dates" :key="item" dense :value="item" class="fade f16 fw500" :class="filter.date !== item && 'secondary-2--text'">{{ $dateFormat.MMYY(item) }}</v-chip>
        </v-chip-group>

        <LinearProgress v-if="loadingCourses" :landing="true" class="mt-3 mb-1"/>

        <div v-if="other_courses.length === 0 && !loadingCourses && !loadingDates && search === '' && filter.date === 'all'" class="fw600 f14 secondary--text my-3"><i>No Courses Available this time. Come back soon!</i></div>

        <div v-if="other_courses.length === 0 && !loadingCourses && !loadingDates" class="fw600 f14 secondary--text my-3"><i>No Courses Found.</i></div>

        <div v-if="other_courses.length>0 && !loadingCourses" class="d-flex flex-wrap mt-3 fade" :class="[$vuetify.breakpoint.mobile && 'justify-center']">
            <galleryCard 
                v-if="!$vuetify.breakpoint.mobile"
                v-for="course in other_courses"
                :key="course.id"
                :course="course" 
                class="ma-2" />
            <listCard 
                v-if="$vuetify.breakpoint.mobile"
                v-for="course in other_courses"
                :key="course.id"
                :course="course" 
                class="ma-2" />
        </div>

        <FormPagination 
            :wrap="false"
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginationPaginate"
            @page="onPageChange" 
            @paginate="onPaginateChange"/>
        
    </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import galleryCard from "@/components/landing/cards/gallery-card.vue";
import listCard from "@/components/landing/cards/list-card.vue"
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';

export default {
    mixins: [searchAndPaginateMixin],
    data:() => ({
        search: '',
        loadingCourses: false,
        loadingDates: false,
        pageCount: 1,
        dates: []
    }),
    components: {
        galleryCard,
        listCard
    },
    mounted() {
        this.getDateFilters()
        this.otherCoursesMutation([])
    },
    computed: {
        ...mapState({
            other_courses: (state) =>  state.other_courses,
        }),

        paginationPaginate(){
            return String(this.paginate)
        }
    },
    methods: {
        ...mapActions(['getCourseAvailabilityDatesAction', 'getGroupedOtherCoursesAction']),
        ...mapMutations(['otherCoursesMutation']),

        getDateFilters(){
            if(!this.loadingDates){
                this.dates = []
                this.loadingDates = true
                this.getCourseAvailabilityDatesAction().then((res) => {
                    this.dates = res
                    this.loadingDates = false
                }).catch(() => {
                    this.loadingDates = false
                })
            }
        },

        getData(){
            if(!this.loadingCourses && !this.loadingDates){
                this.loadingCourses = true
                this.getGroupedOtherCoursesAction({ search: this.search, date: this.filter.date, paginate: Number(this.paginate), page: this.page }).then(res => {
                    this.loadingCourses = false
                    this.paginate = String(res.per_page)
                    this.page = res.current_page
                    this.pageCount = res.last_page
                    this.totalCount = res.total
                }).catch(() => {
                    this.loadingCourses = false
                })
            }
        },

        selectedDateChanged(val){
            this.page = 1
            this.filter.date = val
            this.updateQuery()
        }
    },
}
</script>